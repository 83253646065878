.chatbot-wrapp {
    position: fixed;
    /* bo */
    bottom: 15px;
    border-radius: 50%;
    background: #d71219;
    z-index: 100;
    right: 10px;
    background-image: url(https://cdn.livechat-files.com/api/file/lc/img/14941578/805c299c18d5855aff3b61da6de33a81.png);
    background-size: cover;
    cursor: pointer;
}

.chat-img {
    border: 1px solid #fff;
    border-radius: 50%;
    text-align: center;
    background-color: #fff;
    text-transform: uppercase;
    overflow: hidden;
    --primary-color: #2000F0;
    --secondary-color: #FFFFFF;
    --tertiary-color: #E3E3E3;
    width: 20px;
    height: 20px;
    line-height: 20px;
    border: 0;
    background-color: transparent;
    width: 60px;
    height: 60px;
    line-height: 60px;
    pointer-events: none;
}