.header-main {
    position: sticky;
    top: 0;
    z-index: 50;
}

.header-top {
    background-color: var(--header-color);
}

.marquee-wrapper {
    font-size: 12px;
    font-weight: 600;
    padding: 3px;
    border-radius: 10px;
    background: var(--navbar-color);
}



.header-top .img-help {
    width: 18px;
    filter: invert(1);
    display: flex;
}

.php-flag {
    width: 25px;
    height: 18px;
}


.header-logo {
    padding: 10px;
    align-self: center;
}


.header-bottom {
    background: var(--navbar-color);
    padding: 0;
    filter: drop-shadow(0px 0px 20px black);
}


.navbar-holder {
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-gap: 15px;
}

.header-logo img {
    cursor: pointer;
    animation: blinkAnimation 2s infinite;
    height: auto;
    width: 100px;
    height: auto;
}

@keyframes blinkAnimation {

    50% {
        opacity: .5;
    }

    100% {
        opacity: 1;
    }
}

.navbar-nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: row;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.navbar-nav .nav-link {
    color: var(--navbar-text);
    font-weight: 600;
    padding: 13px 5px;
    height: 100%;
    min-width: 90px;
    max-width: 90px;
    position: relative;
    text-align: center;
}


.navbar-nav .nav-link:before {
    content: "";
    height: 3px;
    background: var(--secondary-color);
    width: 80%;
    position: absolute;
    bottom: 0;
    right: 50%;
    transform: scale(0) translateX(50%);
    transition: all 0.2s ease-in-out;
}

.navbar-nav .nav-link:hover:before {
    transform: scale(1) translateX(50%);
    transition: all 0.2s ease-in-out;
}

.navbar-nav.nav-link:hover {
    color: #158FEA;
    transition: all 0.2s ease-in-out;
}

.navbar-nav .nav-link-name {
    color: var(--secondary-color);
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.form-button {
    margin: auto;
    display: flex;
    flex-direction: column;
}

.navbar-nav .nav-link {
    color: var(--navbar-text);
    font-weight: 600;
    padding: 13px 5px;
    height: 100%;
    min-width: 90px;
    max-width: 90px;
    position: relative;
    text-align: center;
}

/* button right */
.form-button .btn-login {
    background: var(--secondary-color);
    border: 1px solid var(--secondary-color);
    color: var(--c-white);
    min-width: 150px;
    font-size: 14px;
    font-weight: 900;
    border-radius: 10px;
    padding: 5px 10px;
    margin-bottom: 5px;
}

.form-button .btn-register {
    background: #ce0000;
    border: 1px solid #ce0000;
    color: var(--c-white);
    font-size: 14px;
    font-weight: 900;
    min-width: 150px;
    border-radius: 10px;
    padding: 5px 10px;
}

/* button right end */

.header-mobile {
    display: none;
}


@media screen and (max-width: 1199px) {
    .header-main {
        display: none;
    }

    .header-mobile {
        display: block;
        position: sticky;
        top: 0;
        z-index: 100;
        background: #130000;
        color: #fff;
        box-shadow: 0 0 20px 5px #000;
    }

    .mobile-logo img {
        width: 85px;
        height: 70px;
    }

    .header-mobile__top {
        display: grid;
        grid-template-columns: auto 1fr auto;
        padding: 8px;
    }

    .header-mobile__top .mobile-button img,
    .header-mobile__top .mobile-chat img {
        width: 25px;
        filter: invert(1);
    }

    .header-mobile__top .mobile-button,
    .header-mobile__top .mobile-logo,
    .header-mobile__top .mobile-chat {
        align-self: center;
    }

    .header-mobile__top .mobile-logo {
        justify-self: center;
    }


    .header-marquee .marquee-icon img {
        filter: invert(1);
        width: 15px;
        display: flex;
    }

    .header-marquee {
        background: var(--primary-color);
        display: flex;
        align-items: center;
        width: 100%;
    }

    .header-marquee .marquee {
        font-size: 12px;
        font-weight: 600;
        width: 100%;
        display: inline-block;
        text-align: initial;
        white-space: nowrap;
        animation: marquee 22s linear infinite;
        margin-bottom: 0;
        color: #fff;
        width: 100vw;

    }

    @keyframes marquee {
        0% {
            transform: translateX(100%);
        }

        100% {
            transform: translateX(-100%);
        }
    }

    .header-mobile__login {
        border-radius: 15px 15px 0 0;
        background: #161821;
        filter: drop-shadow(0px 0px 10px black);
        color: #fff;
        position: relative;
    }

    .header-mobile__login .footer-button {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .header-mobile__login .footer-button .btn-login {
        background: var(--secondary-color);
        color: var(--secondary-text);
    }
}

