.jackpot-bg {
    background-image: url(/public/assets/img/jackpot.gif);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    height: 220px;
}

.jackpot-amount {
    font-size: 32px;
    font-weight: 600;
    color: var(--jackpot-text);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 20px;
    left: 15px;
    padding-right: 50px;
    letter-spacing: 3px;
    font-family: advanced_dot_digital-7regular;
}

/* home game */
.game-container__header {
    display: flex;
    align-items: center;
    padding: 10px 0;
}

.game-container__header .game-title {
    display: flex;
    align-items: center;
}

.game-container__header .game-title .icon {
    margin-right: 15px;
}

.game-title .icon img {
    width: 60px;
}

img {
    background-repeat: no-repeat;
    background-position: 50% 50%;
}

.game-container__header .game-title h6 {
    margin-bottom: 0;
    font-size: 35px;
    font-weight: 600;
    color: var(--c-white);
    white-space: nowrap;
}

.game-container__header .game-title {
    display: flex;
    align-items: center;
}


.game-container__header .game-action {
    margin-left: auto;
    display: flex;
    align-items: center;
}


.game-container__header .game-action .action-button {
    margin-right: 5px;
}

.game-container__header .game-action .action-button .btn-custom-sm {
    border-radius: 50px;
    font-size: 12px;
    padding: 3px 10px;
    font-weight: 700;
}

.btn-custom-sm {
    background: #911619;
    border: 1px solid #911619;
    padding: 6px 12px;
    font-size: 13px;
    font-weight: 600;
    border-radius: 5px;
    color: var(--secondary-text);
}

.game-container__header .game-action .action-navigation .btn-custom-sm {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    border-radius: 25px;
}

.game-container__header .game-action .action-navigation {
    display: flex;
    align-items: center;
}

.game-action .action-navigation .btn-custom-sm.swiper-button-disabled {
    background: rgba(255, 255, 255, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: var(--page-text);
}


.game-container__header .game-action .action-navigation .btn-custom-sm i {
    font-size: 16px;
}

/* home game end */

/* home game list  */

.games-holder {
    width: 214px;

}

.game-container__list {
    margin-top: 5px;
}


.game-container__list .games-holder {
    text-align: center;
    position: relative;
    overflow: hidden;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;

}

.game-container__list .games-holder .games-img {
    background: var(--primary-color);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    border-radius: 15px 15px 0 0;
}

.game-container__list .games-holder .games-img img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
}

.games-holder .games-bottom {
    background: var(--primary-color);
    color: var(--primary-text);
    font-size: 14px;
    padding: 5px 8px;
    font-weight: 600;
}


.game-container__list .games-holder:hover .hover-play {
    transform: scale(1);
    transition: all 0.2s ease-in-out;
}

.game-container__list .games-holder .hover-play {
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    border-radius: 15px 15px 0 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scale(0);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.game-container__list .games-holder .games-bottom {
    background: var(--primary-color);
    color: var(--primary-text);
    font-size: 14px;
    padding: 5px 8px;
    font-weight: 600;
}

.game-container__list .games-holder .games-bottom .bottom-info {
    text-align: left;
    line-height: 1;
}

.game-container__list .games-holder .games-bottom .bottom-info .info-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 3px;
    padding-bottom: 2px;
}

.game-container__list .games-holder .games-bottom .bottom-info .info-provider {
    color: var(--secondary-color);
    font-size: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.swiper-slide {
    margin-right: 20px;
}

.swiper-slide {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    position: relative;
    transition-property: transform;
}

.game-container__category-list .slide-border {
    background: transparent;
    padding: 2px;
    border-radius: 15px;
}


.game-container__category-list .slide-border .slide-item {
    background: var(--primary-color);
    border-radius: 15px;
    position: relative;
}


.game-container__category-list .slide-border .slide-item .slide-img {
    position: relative;
    overflow: hidden;
}

.game-container__category-list .slide-border .slide-item .slide-img img {
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
    -o-object-position: center;
    object-position: center;
}


.game-container__category-list .slide-border:hover .slide-item .hover-play {
    transform: scale(1);
    transition: all 0.2s ease-in-out;
}

.game-container__category-list .slide-border .slide-item .slide-img .hover-play {
    background: rgba(0, 0, 0, 0.5);
    color: var(--c-white);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transform: scale(0);
    transition: all 0.2s ease-in-out;
}

.game-container__category-list .slide-border .slide-item .slide-img .hover-play h6 {
    font-size: 11px;
    margin-bottom: 20px;
    font-weight: 600;
    background: var(--secondary-color);
    border-radius: 25px;
    padding: 5px 5px;
    max-width: 120px;
    min-width: 120px;
    text-align: center;
    position: absolute;
    top: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.game-container__category-list .slide-border .slide-item .slide-img .hover-play .fas {
    color: var(--c-white);
    font-size: 20px;
    background: rgba(255, 255, 255, 0.4);
    border-radius: 50px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* home game list end */


/* payment */
.game-container__bank-list .bank-holder {
    background: var(--primary-color);
    padding: 2px;
    border-radius: 25px;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    position: relative;
    overflow: visible;
    margin-top: 10px;
}

.game-container__bank-list .bank-holder .bank-status.online {
    background: #52AC62;
}

.game-container__bank-list .bank-holder .bank-status {
    position: absolute;
    font-size: 10px;
    font-weight: 600;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    padding: 2px 8px;
    border-radius: 5px;
    color: var(--c-white);
}

.game-container__bank-list .bank-holder .bank-img {
    position: relative;
    overflow: hidden;
}


.game-container__bank-list .bank-holder .bank-img img {
    max-width: 80px;
}

/* payment end */

/* best */
.seo-content {
    background: var(--primary-color);
    border-radius: 15px;
    padding: 10px 15px;
    color: var(--primary-text);
    font-size: 14px;
}


.seo-1 span strong,
.header-best {
    color: hsl(60, 75%, 60%);

}

a {
    text-decoration: none;

}

.best-link {
    color: hsl(0, 75%, 60%);

}


/* best end */

/* figure table */
.home .home__seo .seo-content .table {
    overflow-x: auto;
}




.tables {
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
}

span .best-highlight {
    color: hsl(210, 75%, 60%);
}

/* figure table end */

@media only screen and (max-width: 991px) {
    .jackpot-amount {
        font-size: 24px;
        top: 13px;
    }
}

@media screen and (max-width: 767px) {
    .home__jackpot .jackpot-gif .jackpot-amount {
        font-size: 10px;
    }

    .game-container__header .game-title h6 {
        margin-bottom: 0;
        font-size: 10px;
        font-weight: 600;
        color: #fff;
        white-space: nowrap;
    }


}

@media screen and (max-width: 426px) {
    .jackpot-amount {
        font-size: 10px;
        top: 4px;
    }

    .jackpot-amount span {
        margin-left: 2px;

    }

}