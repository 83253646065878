.footer__holder .footer-col .footer-title {
    font-size: 16px;
    font-weight: 600;
    color: var(--footer-text);
    margin-bottom: 5px;
}

.footer__holder .footer-col .footer-service {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
}

.footer__holder .footer-col .footer-service .service-item {
    display: flex;
    align-items: center;
}

.footer__holder .footer-col .footer-service .service-item .service-icon {
    margin-right: 5px;
}

.footer__holder .footer-col .footer-service .service-item .service-icon img {
    width: 50px;
    filter: invert(1);
}

.footer__holder .footer-col .footer-service .service-item .service-time {
    padding: 5px;
    border-radius: 5px;
    background: var(--primary-color);
    color: var(--c-white);
    width: 100%;
    line-height: 1;
}

.footer__holder .footer-col .footer-service .service-item .service-time .time-title {
    font-size: 12px;
    margin-bottom: 3px;
}

.footer__holder .footer-col .footer-service .service-item .service-time .time-number {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 3px;
}

.footer__holder .footer-col .footer-service .service-item .service-time .progress {
    height: 12px;
    padding: 2px 3px;
    border-radius: 25px;
    margin-bottom: 0;
    overflow: hidden;
}

.progress {
    display: -ms-flexbox;
    display: flex;
    height: 1rem;
    overflow: hidden;
    line-height: 0;
    font-size: .75rem;
    background-color: #e9ecef;
    border-radius: 0.25rem;
}

.footer__holder .footer-col .footer-service .service-item .service-time .progress .progress-bar {
    background: var(--secondary-color);
    border-radius: 25px;
    position: relative;
}

.progress-bar {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #007bff;
    transition: width .6s ease;
}

.footer__holder .footer-col .footer-service .service-item .service-time .progress .progress-bar::before {
    content: "";
    position: absolute;
    height: 12px;
    border-radius: 25px;
}

.footer__holder .footer-col {
    padding: 25px 0;
}

.footer__holder .footer-col .footer-title {
    font-size: 16px;
    font-weight: 600;
    color: var(--footer-text);
    margin-bottom: 5px;
}

.footer__holder .footer-col .footer-contact {
    display: flex;
    align-items: center;
    grid-gap: 10px;
}

.footer__holder .footer-col .footer-title {
    font-size: 16px;
    font-weight: 600;
    color: var(--footer-text);
    margin-bottom: 5px;
}

.footer__holder .footer-col .footer-provider {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.footer__holder .footer-col .footer-provider .provider-icon {
    background: var(--header-color);
    margin: 3px;
    padding: 3px;
    min-width: 95px;
    border-radius: 10px;
}

.footer__holder .footer-nav {
    text-align: center;
    padding: 20px 0;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.footer__holder .footer-nav .footer-link {
    text-decoration: none;
    color: var(--footer-text);
    font-size: 14px;
    font-weight: 600;
    margin: 0 10px;
}

.footer__holder .footer-nav {
    text-align: center;
    padding: 20px 0;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.footer__trademark {
    background: var(--footer-color);
    text-align: center;
    color: var(--footer-text);
    font-size: 12px;
    padding: 20px 0;
    font-weight: 500;
}