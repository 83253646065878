@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:wght@400;500;600&display=swap');

* {
  padding: 0;
  box-sizing: border-box;
  color: #fff;
}

@font-face {
  font-family: advanced_dot_digital-7regular;
  src: url(/public/assets/fonts/advanced_dot_digital-7-webfont.woff2);
}

:root {
  --header-color: #151720;
  --navbar-color: #262A3B;
  --primary-color: #151720;
  --secondary-color: #158FEA;
  --lottery-color: #200E45;
  --footer-color: #262A3B;
  --jackpot-color: #151720;
  --header-text: #ffffff;
  --navbar-text: #ffffff;
  --jackpot-text: #ffffff;
  --primary-text: #ffffff;
  --secondary-text: #ffffff;
  --lottery-text: #ffffff;
  --footer-text: #ffffff;
  --c-white: #ffffff;
  --c-black: #0c0c0c;
  --c-grey: #181818;
  /* --background-theme: url(../../2/img/header/bod.jpg);
  --jackpot-theme: url(../../custom/img/header/jackpot.gif); */
  --background-page: #151720;
  --page-text: #ffffff;
}

body {
  margin: 0;
  margin-bottom: 0 !important;
  font-family: "Chakra Petch", sans-serif;
  background-color: #262A3B;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


